



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IWork } from '@store/modules/work-list/interfaces/Interfaces';
import { WorkListMutations, WorkListActions } from '@store/modules/work-list/Types';
import { ISearchResult } from "@store/modules/project/Interfaces";

const NSWorkList = namespace('storeWorkList');
const NSProject = namespace('storeProject');

@Component({
    name: 'WorkComposite',

    components: {
        'BaseComposite': () => import('@components/BaseComposite/BaseComposite.vue'),
    },
})
export default class WorkComposite extends Vue {
    @Prop({ default: false }) disableDragDrop!: boolean;
    @Prop({ default: false }) usingInExecutiveDocs!: boolean;

    @NSWorkList.Getter('workList') workList!: IWork[];
    @NSWorkList.Getter('viewingWork') viewingWork!: IWork;
    @NSProject.Getter('searchResult') searchResult!: ISearchResult;
    @NSWorkList.Getter('pathToViewingWork') pathToViewingWork!: Set<IWork>;
    @NSWorkList.Action(WorkListActions.A_SET_VIEWING_WORK) setViewingWork!: (id: number | string) => void;
    @NSWorkList.Mutation(WorkListMutations.M_SET_VIEWING_WORK_TITLE) setViewingWorkTitle!: (work: IWork) => void;
    @NSWorkList.Action(WorkListActions.A_SET_SEARCH_RESULTS_IDS_CHAIN) setSearchResultsIdsChain!: (payload) => void;
    @NSWorkList.Action(WorkListActions.A_SET_PATH_TO_VIEWING_WORK) setPathToViewingWork!: (payload: Set<IWork>) => Promise<void>;

    ids: string[] = [];
    relatedIds: {} = {};
    relatedIdsComposite: {} = {};
    folderOfWork: any;
    idsChain: string[] = [];

    mounted() {
        if (this.viewingWork) {
            this.setViewingWork(this.viewingWork.uuid);
            this.setPathToViewingWork(this.pathToViewingWork);
        }
        this.checkSearchResult();
    }

    @Watch('searchResult')
    watchSearchResult() {
        this.checkSearchResult();
    }

    selectWorkToView(selectedComposite, path?, composite?) {
        this.setViewingWork(selectedComposite.uuid);
        this.setViewingWorkTitle(selectedComposite);
        this.setPathToViewingWork(composite);
    }

    checkSearchResult() {
        if (this.searchResult && this.searchResult.group === 'Работы') {
            this.makeChainIds(this.searchResult.item.id);
        }
        if (this.searchResult && this.searchResult.group === 'Конструктивы') {
            this.recursionForFindFolder(this.workList, this.searchResult.item.id).then(() => {
                this.selectWorkToView(this.folderOfWork);
                this.makeChainIdsForComposites()
            })
        }
    }

    makeChainIds(id) {
        this.workList.forEach((item) => {
            this.relatedIds[item.uuid] = null;
        })
        this.recursionForRelation(this.workList).then(() => {
            this.idsChain.push(id);
            this.recursionForFinalIdsChain(id).then(() => {
                this.idsChain = this.idsChain.reverse();
                this.setSearchResultsIdsChain(this.idsChain);
                setTimeout(() => {
                    this.idsChain = [];
                }, 500);
            });
        });
    }

    makeChainIdsForComposites() {
        this.recursionForRelationComposite(this.workList).then(() => {
            if (this.searchResult && this.searchResult.item.id) {
                this.makeChainIds(this.relatedIdsComposite[this.searchResult.item.id])
            }
        });
    }

    async recursionForRelation(list) {
        list.forEach((item) => {
            if (item.children && item.children.length) {
                item.children.forEach((child) => {
                    this.relatedIds[child.uuid] = item.uuid;
                });
                this.recursionForRelation(item.children);
            }
        })
    }

    async recursionForRelationComposite(list) {
        list.forEach((item) => {
            if (item.constructs && item.constructs.length) {
                item.constructs.forEach((construct) => {
                    this.relatedIdsComposite[construct.uuid] = item.uuid;
                });
            }
            if (item.children && item.children.length) {
                this.recursionForRelationComposite(item.children);
            }
        })
    }

    async recursionForFindFolder(list, constructId) {
        list.forEach((item) => {
            if (item.children && item.children.length) {
                this.recursionForFindFolder(item.children, constructId);
            }
            if (item.constructs && item.constructs.length) {
                if (item.constructs.filter(construct => construct.uuid === constructId).length) {
                    this.folderOfWork = JSON.parse(JSON.stringify(item));
                }
            }
        })
    }

    async recursionForFinalIdsChain(id) {
        for (const key in this.relatedIds) {
            if (key === id && this.relatedIds[key] && !this.idsChain.includes(this.relatedIds[key])) {
                this.idsChain.push(this.relatedIds[key]);
                await this.recursionForFinalIdsChain(this.relatedIds[key]);
            }
        }
    }
}
